*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 320px) {
  .container {
    max-width: 320px;
  }
}

@media (min-width: 360px) {
  .container {
    max-width: 360px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

body {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  font-family: Lato-Regular, sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Eczar-Bold, sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

h3 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

code {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: 1rem;
  font-family: UbuntuMono-Regular, ui-monospace, SFMono-Regular;
  font-size: .75rem;
  line-height: 1rem;
}

.trustline-metric {
  height: 5rem;
  width: 5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  padding: .5rem;
  display: flex;
}

.tooltip {
  position: relative;
}

.tooltip .tooltip-text {
  pointer-events: none;
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  opacity: 0;
  border-radius: .375rem;
  margin-top: -2rem;
  margin-left: .5rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition-property: opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
}

.tooltip:hover .tooltip-text {
  opacity: 1;
}

.scrollable-overflow {
  overflow: auto;
}

.div-pill {
  text-align: center;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: 9999px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 700;
  display: flex;
}

.link-common {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
  text-decoration-line: underline;
}

.link-common:hover {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.div-link {
  cursor: pointer;
}

.div-link:hover {
  text-decoration-line: underline;
}

.btn-common {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: .25rem;
  padding: .25rem;
  font-weight: 700;
  display: flex;
}

.btn-common:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
}

.btn-approve {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: .25rem;
  padding: .25rem;
  display: flex;
}

.btn-approve:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn-cancel {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin: .25rem;
  padding: .25rem;
  display: flex;
}

.btn-cancel:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.alert-success {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: .5rem;
}

.alert-danger {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: .5rem;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.\!left-auto {
  left: auto !important;
}

.\!top-auto {
  top: auto !important;
}

.-bottom-\[47px\] {
  bottom: -47px;
}

.-left-\[15px\] {
  left: -15px;
}

.-top-\[21px\] {
  top: -21px;
}

.-top-\[35px\] {
  top: -35px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1\/2 {
  bottom: 50%;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-\[2px\] {
  bottom: 2px;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-3 {
  left: .75rem;
}

.left-\[calc\(50\%-1px\)\] {
  left: calc(50% - 1px);
}

.right-0 {
  right: 0;
}

.right-0\.5 {
  right: .125rem;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-3 {
  right: .75rem;
}

.right-9 {
  right: 2.25rem;
}

.right-\[2px\] {
  right: 2px;
}

.right-\[3\] {
  right: 3px;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-\[11px\] {
  top: 11px;
}

.top-\[13px\] {
  top: 13px;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-\[1040\] {
  z-index: 1040;
}

.z-\[1065\] {
  z-index: 1065;
}

.z-\[1066\] {
  z-index: 1066;
}

.z-\[1070\] {
  z-index: 1070;
}

.z-\[1080\] {
  z-index: 1080;
}

.z-\[999\] {
  z-index: 999;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.\!my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-\[5px\] {
  margin-top: 5px;
  margin-bottom: 5px;
}

.-mt-3 {
  margin-top: -.75rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[10px\] {
  margin-bottom: 10px;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-\[30px\] {
  margin-left: 30px;
}

.ml-\[3px\] {
  margin-left: 3px;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-\[8px\] {
  margin-right: 8px;
}

.mr-auto {
  margin-right: auto;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[0\.15rem\] {
  margin-top: .15rem;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.\!block {
  display: block !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.\!h-0 {
  height: 0 !important;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-2\/5 {
  height: 40%;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[1\.125rem\] {
  height: 1.125rem;
}

.h-\[100\], .h-\[100px\] {
  height: 100px;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[15px\] {
  height: 15px;
}

.h-\[160px\] {
  height: 160px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[260px\] {
  height: 260px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[380px\] {
  height: 380px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[48\], .h-\[48px\] {
  height: 48px;
}

.h-\[4px\] {
  height: 4px;
}

.h-\[512px\] {
  height: 512px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[72px\] {
  height: 72px;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[calc\(100\%-64px\)\] {
  max-height: calc(100% - 64px);
}

.min-h-\[100\] {
  min-height: 100px;
}

.min-h-\[305px\] {
  min-height: 305px;
}

.min-h-\[325px\] {
  min-height: 325px;
}

.min-h-\[auto\] {
  min-height: auto;
}

.min-h-fit {
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-2 {
  width: .5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/4 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[1\.125rem\] {
  width: 1.125rem;
}

.w-\[100\] {
  width: 100px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[15px\] {
  width: 15px;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[180\] {
  width: 180px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[250\] {
  width: 250px;
}

.w-\[260px\] {
  width: 260px;
}

.w-\[2px\] {
  width: 2px;
}

.w-\[300\] {
  width: 300px;
}

.w-\[304px\] {
  width: 304px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[328px\] {
  width: 328px;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[350\] {
  width: 350px;
}

.w-\[450\] {
  width: 450px;
}

.w-\[48\] {
  width: 48px;
}

.w-\[4px\] {
  width: 4px;
}

.w-\[50\] {
  width: 50px;
}

.w-\[6px\] {
  width: 6px;
}

.w-\[72px\] {
  width: 72px;
}

.w-\[76px\] {
  width: 76px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[310px\] {
  min-width: 310px;
}

.min-w-\[48px\] {
  min-width: 48px;
}

.min-w-\[64px\] {
  min-width: 64px;
}

.max-w-\[140\] {
  max-width: 140px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[267px\] {
  max-width: 267px;
}

.max-w-\[300\] {
  max-width: 300px;
}

.max-w-\[325px\] {
  max-width: 325px;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-\[calc\(100\%-1rem\)\] {
  max-width: calc(100% - 1rem);
}

.max-w-full {
  max-width: 100%;
}

.flex-auto {
  flex: auto;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.basis-auto {
  flex-basis: auto;
}

.table-auto {
  table-layout: auto;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-x-0 {
  --tw-border-spacing-x: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-x-1 {
  --tw-border-spacing-x: .25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-1 {
  --tw-border-spacing-y: .25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.border-spacing-y-2 {
  --tw-border-spacing-y: .5rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.origin-\[0_0\] {
  transform-origin: 0 0;
}

.origin-\[center_bottom_0\] {
  transform-origin: center bottom 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[150\%\] {
  --tw-translate-x: -150%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[150\%\] {
  --tw-translate-x: 150%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[0\.8\] {
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\[1\.02\] {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-none {
  transform: none;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-\[fade-in_0\.15s_both\] {
  animation: .15s both fade-in;
}

.animate-\[fade-in_0\.3s_both\] {
  animation: .3s both fade-in;
}

.animate-\[fade-in_350ms_ease-in-out\] {
  animation: .35s ease-in-out fade-in;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animate-\[fade-out_0\.15s_both\] {
  animation: .15s both fade-out;
}

.animate-\[fade-out_0\.3s_both\] {
  animation: .3s both fade-out;
}

.animate-\[fade-out_350ms_ease-in-out\] {
  animation: .35s ease-in-out fade-out;
}

@keyframes show-up-clock {
  0% {
    opacity: 0;
    transform: scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-\[show-up-clock_350ms_linear\] {
  animation: .35s linear show-up-clock;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.touch-none {
  touch-action: none;
}

.touch-pan-y {
  --tw-pan-y: pan-y;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-all {
  -webkit-user-select: all;
  user-select: all;
}

.resize {
  resize: both;
}

.list-decimal {
  list-style-type: decimal;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  appearance: none;
}

.\!flex-row {
  flex-direction: row !important;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-3 {
  gap: .75rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded, .rounded-\[0\.25rem\] {
  border-radius: .25rem;
}

.rounded-\[0\.6rem\] {
  border-radius: .6rem;
}

.rounded-\[100\%\] {
  border-radius: 100%;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[16px\] {
  border-radius: 16px;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-\[999px\] {
  border-radius: 999px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-\[0\.25rem\] {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-r-\[0\.25rem\] {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-t-\[0\.6rem\] {
  border-top-left-radius: .6rem;
  border-top-right-radius: .6rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.\!border-\[3px\] {
  border-width: 3px !important;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-\[\.125rem\], .border-\[0\.125rem\] {
  border-width: .125rem;
}

.border-\[14px\] {
  border-width: 14px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-\[0\.125rem\] {
  border-left-width: .125rem;
}

.border-r-0 {
  border-right-width: 0;
}

.\!border-solid {
  border-style: solid !important;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.\!border-\[\#b2b3b4\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(178 179 180 / var(--tw-border-opacity)) !important;
}

.border-\[\#3b71ca\] {
  --tw-border-opacity: 1;
  border-color: rgb(59 113 202 / var(--tw-border-opacity));
}

.border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgb(207 250 254 / var(--tw-border-opacity));
}

.border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(59 113 202 / var(--tw-border-opacity));
}

.border-primary-400 {
  --tw-border-opacity: 1;
  border-color: rgb(143 174 224 / var(--tw-border-opacity));
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-t-transparent {
  border-top-color: #0000;
}

.\!bg-\[\#858585\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(133 133 133 / var(--tw-bg-opacity)) !important;
}

.\!bg-danger-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 229 233 / var(--tw-bg-opacity)) !important;
}

.\!bg-primary-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(227 235 247 / var(--tw-bg-opacity)) !important;
}

.\!bg-success-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(214 250 228 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#00000012\] {
  background-color: #00000012;
}

.bg-\[\#00000066\] {
  background-color: #0006;
}

.bg-\[\#3b71ca\] {
  --tw-bg-opacity: 1;
  background-color: rgb(59 113 202 / var(--tw-bg-opacity));
}

.bg-\[\#6d6d6d\] {
  --tw-bg-opacity: 1;
  background-color: rgb(109 109 109 / var(--tw-bg-opacity));
}

.bg-\[\#aaa\] {
  --tw-bg-opacity: 1;
  background-color: rgb(170 170 170 / var(--tw-bg-opacity));
}

.bg-\[\#eceff1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 239 241 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/10 {
  background-color: #0000001a;
}

.bg-black\/40 {
  background-color: #0006;
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

.bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(59 113 202 / var(--tw-bg-opacity));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/30 {
  background-color: #ffffff4d;
}

.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 98 7 / var(--tw-bg-opacity));
}

.bg-zinc-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

.bg-zinc-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.bg-zinc-600\/50 {
  background-color: #52525b80;
}

.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-\[url\(\'\.\/assets\/img\/centigrade-bg\.png\'\)\] {
  background-image: url("centigrade-bg.3377ff06.png");
}

.bg-cover {
  background-size: cover;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.fill-\[\#afafaf\] {
  fill: #afafaf;
}

.fill-white {
  fill: #fff;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[5px\] {
  padding: 5px;
}

.p-\[auto\] {
  padding: auto;
}

.\!py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-\[0\.4rem\] {
  padding-left: .4rem;
  padding-right: .4rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}

.px-\[auto\] {
  padding-left: auto;
  padding-right: auto;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-\[0\.32rem\] {
  padding-top: .32rem;
  padding-bottom: .32rem;
}

.py-\[0\.33rem\] {
  padding-top: .33rem;
  padding-bottom: .33rem;
}

.py-\[0\.4rem\] {
  padding-top: .4rem;
  padding-bottom: .4rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[1px\] {
  padding-top: 1px;
  padding-bottom: 1px;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-\[50px\] {
  padding-left: 50px;
}

.pl-\[8px\] {
  padding-left: 8px;
}

.pr-\[24px\] {
  padding-right: 24px;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-\[0\.37rem\] {
  padding-top: .37rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.font-heading {
  font-family: Eczar-Regular, ui-sans-serif;
}

.font-mono {
  font-family: ui-monospace, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[0\.8rem\] {
  font-size: .8rem;
}

.text-\[0\.9rem\] {
  font-size: .9rem;
}

.text-\[1\.1rem\] {
  font-size: 1.1rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[2\.5rem\] {
  font-size: 2.5rem;
}

.text-\[3\.75rem\] {
  font-size: 3.75rem;
}

.text-\[34px\] {
  font-size: 34px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-\[1\.5\] {
  line-height: 1.5;
}

.leading-\[1\.6\] {
  line-height: 1.6;
}

.leading-\[2\.15\] {
  line-height: 2.15;
}

.leading-\[40px\] {
  line-height: 40px;
}

.leading-loose {
  line-height: 2;
}

.leading-normal {
  line-height: 1.5;
}

.tracking-\[-0\.00833em\] {
  letter-spacing: -.00833em;
}

.tracking-\[\.1rem\], .tracking-\[0\.1rem\] {
  letter-spacing: .1rem;
}

.tracking-\[1\.7px\] {
  letter-spacing: 1.7px;
}

.tracking-normal {
  letter-spacing: 0;
}

.\!text-danger-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(176 35 58 / var(--tw-text-opacity)) !important;
}

.\!text-gray-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(249 250 251 / var(--tw-text-opacity)) !important;
}

.\!text-primary {
  --tw-text-opacity: 1 !important;
  color: rgb(59 113 202 / var(--tw-text-opacity)) !important;
}

.\!text-primary-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(40 81 146 / var(--tw-text-opacity)) !important;
}

.\!text-success-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(14 117 55 / var(--tw-text-opacity)) !important;
}

.\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-\[\#212529\] {
  --tw-text-opacity: 1;
  color: rgb(33 37 41 / var(--tw-text-opacity));
}

.text-\[\#3b71ca\] {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.text-\[\#4f4f4f\] {
  --tw-text-opacity: 1;
  color: rgb(79 79 79 / var(--tw-text-opacity));
}

.text-\[\#b3afaf\] {
  --tw-text-opacity: 1;
  color: rgb(179 175 175 / var(--tw-text-opacity));
}

.text-\[\#ffffff8a\] {
  color: #ffffff8a;
}

.text-\[rgb\(220\,76\,100\)\] {
  --tw-text-opacity: 1;
  color: rgb(220 76 100 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black\/50 {
  color: #00000080;
}

.text-black\/\[64\] {
  color: #000;
}

.text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgb(254 240 138 / var(--tw-text-opacity));
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.underline-offset-auto {
  text-underline-offset: auto;
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}

.\!opacity-0 {
  opacity: 0 !important;
}

.\!opacity-100 {
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-\[\.53\] {
  opacity: .53;
}

.opacity-\[\.54\] {
  opacity: .54;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0px_3px_0_rgba\(0\,0\,0\,0\.07\)\,0_2px_2px_0_rgba\(0\,0\,0\,0\.04\)\] {
  --tw-shadow: 0 0px 3px 0 #00000012, 0 2px 2px 0 #0000000a;
  --tw-shadow-colored: 0 0px 3px 0 var(--tw-shadow-color), 0 2px 2px 0 var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_10px_15px_-3px_rgba\(0\,0\,0\,0\.07\)\,0_4px_6px_-2px_rgba\(0\,0\,0\,0\.05\)\] {
  --tw-shadow: 0 10px 15px -3px #00000012, 0 4px 6px -2px #0000000d;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_2px_5px_0_rgba\(0\,0\,0\,0\.16\)\,_0_2px_10px_0_rgba\(0\,0\,0\,0\.12\)\] {
  --tw-shadow: 0 2px 5px 0 #00000029, 0 2px 10px 0 #0000001f;
  --tw-shadow-colored: 0 2px 5px 0 var(--tw-shadow-color), 0 2px 10px 0 var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0px_2px_15px_-3px_rgba\(0\,0\,0\,\.07\)\,_0px_10px_20px_-2px_rgba\(0\,0\,0\,\.04\)\] {
  --tw-shadow: 0px 2px 15px -3px #00000012, 0px 10px 20px -2px #0000000a;
  --tw-shadow-colored: 0px 2px 15px -3px var(--tw-shadow-color), 0px 10px 20px -2px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[background-color\,box-shadow\,border\] {
  transition-property: background-color, box-shadow, border;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[height\] {
  transition-property: height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[opacity\] {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,_opacity\] {
  transition-property: transform, opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[transform\,height\] {
  transition-property: transform, height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[width\] {
  transition-property: width;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-\[0ms\] {
  transition-delay: 0s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-\[250ms\] {
  transition-duration: .25s;
}

.duration-\[350ms\] {
  transition-duration: .35s;
}

.duration-\[400ms\] {
  transition-duration: .4s;
}

.ease-\[cubic-bezier\(0\,0\,0\.15\,1\)\,_cubic-bezier\(0\,0\,0\.15\,1\)\] {
  transition-timing-function: cubic-bezier(0, 0, .15, 1), cubic-bezier(0, 0, .15, 1);
}

.ease-\[cubic-bezier\(0\.25\,0\.1\,0\.25\,1\)\], .ease-\[cubic-bezier\(0\.25\,0\.1\,0\.25\,1\.0\)\] {
  transition-timing-function: ease;
}

.ease-\[cubic-bezier\(0\.4\,0\,0\.2\,1\)\] {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-\[ease\] {
  transition-timing-function: ease;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.\[direction\:ltr\] {
  direction: ltr;
}

.\[overflow-anchor\:none\] {
  overflow-anchor: none;
}

.\[overflow-style\:none\] {
  overflow-style: none;
}

.\[transition\:background-color_\.2s_linear\,_height_\.2s_ease-in-out\] {
  transition: background-color .2s linear, height .2s ease-in-out;
}

.\[transition\:background-color_\.2s_linear\,_opacity_\.2s_linear\] {
  transition: background-color .2s linear, opacity .2s linear;
}

.\[transition\:background-color_\.2s_linear\,_width_\.2s_ease-in-out\] {
  transition: background-color .2s linear, width .2s ease-in-out;
}

.\[transition\:background-color_250ms_cubic-bezier\(0\.4\,0\,0\.2\,1\)_0ms\,box-shadow_250ms_cubic-bezier\(0\.4\,0\,0\.2\,1\)_0ms\,border_250ms_cubic-bezier\(0\.4\,0\,0\.2\,1\)_0ms\] {
  transition: background-color .25s cubic-bezier(.4, 0, .2, 1), box-shadow .25s cubic-bezier(.4, 0, .2, 1), border .25s cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: Eczar-Regular;
  src: url("Eczar-Regular.065aaed9.ttf") format("truetype");
}

@font-face {
  font-family: Eczar-Bold;
  src: url("Eczar-Bold.63e86704.ttf") format("truetype");
}

@font-face {
  font-family: Lato-Regular;
  src: url("Lato-Regular.ad872c70.ttf") format("truetype");
}

@font-face {
  font-family: Lato-Bold;
  src: url("Lato-Bold.f53d8879.ttf") format("truetype");
}

@font-face {
  font-family: UbuntuMono-Regular;
  src: url("UbuntuMono-Regular.f5d2a65f.ttf") format("truetype");
}

@font-face {
  font-family: UbuntuMono-Bold;
  src: url("UbuntuMono-Bold.665e4fcf.ttf") format("truetype");
}

.dropdown {
  position: relative;
}

.dropdown:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid #333;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  position: absolute;
  top: 8px;
  right: 10px;
}

.dropdown:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid #333;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  position: absolute;
  top: 3px;
  right: 10px;
}

.selection\:bg-transparent ::selection, .selection\:bg-transparent::selection {
  background-color: #0000;
}

.before\:pointer-events-none:before {
  content: var(--tw-content);
  pointer-events: none;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:h-\[0\.875rem\]:before {
  content: var(--tw-content);
  height: .875rem;
}

.before\:w-\[0\.875rem\]:before {
  content: var(--tw-content);
  width: .875rem;
}

.before\:scale-0:before {
  content: var(--tw-content);
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:bg-transparent:before {
  content: var(--tw-content);
  background-color: #0000;
}

.before\:opacity-0:before {
  content: var(--tw-content);
  opacity: 0;
}

.before\:shadow-\[0px_0px_0px_13px_transparent\]:before {
  content: var(--tw-content);
  --tw-shadow: 0px 0px 0px 13px transparent;
  --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.before\:content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.checked\:border-primary:checked {
  --tw-border-opacity: 1;
  border-color: rgb(59 113 202 / var(--tw-border-opacity));
}

.checked\:bg-primary:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(59 113 202 / var(--tw-bg-opacity));
}

.checked\:before\:opacity-\[0\.16\]:checked:before {
  content: var(--tw-content);
  opacity: .16;
}

.checked\:after\:absolute:checked:after {
  content: var(--tw-content);
  position: absolute;
}

.checked\:after\:-mt-px:checked:after {
  content: var(--tw-content);
  margin-top: -1px;
}

.checked\:after\:ml-\[0\.25rem\]:checked:after {
  content: var(--tw-content);
  margin-left: .25rem;
}

.checked\:after\:block:checked:after {
  content: var(--tw-content);
  display: block;
}

.checked\:after\:h-\[0\.8125rem\]:checked:after {
  content: var(--tw-content);
  height: .8125rem;
}

.checked\:after\:w-\[0\.375rem\]:checked:after {
  content: var(--tw-content);
  width: .375rem;
}

.checked\:after\:rotate-45:checked:after {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.checked\:after\:border-\[0\.125rem\]:checked:after {
  content: var(--tw-content);
  border-width: .125rem;
}

.checked\:after\:border-l-0:checked:after {
  content: var(--tw-content);
  border-left-width: 0;
}

.checked\:after\:border-t-0:checked:after {
  content: var(--tw-content);
  border-top-width: 0;
}

.checked\:after\:border-solid:checked:after {
  content: var(--tw-content);
  border-style: solid;
}

.checked\:after\:border-white:checked:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.checked\:after\:bg-transparent:checked:after {
  content: var(--tw-content);
  background-color: #0000;
}

.checked\:after\:content-\[\'\'\]:checked:after {
  --tw-content: "";
  content: var(--tw-content);
}

.empty\:hidden:empty {
  display: none;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:rounded-\[50\%\]:hover {
  border-radius: 50%;
}

.hover\:bg-\[\#00000014\]:hover {
  background-color: #00000014;
}

.hover\:bg-\[\#00000026\]:hover {
  background-color: #00000026;
}

.hover\:bg-\[\#eee\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}

.hover\:bg-\[unset\]:hover {
  background-color: unset;
}

.hover\:bg-neutral-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.hover\:fill-\[\#8b8b8b\]:hover {
  fill: #8b8b8b;
}

.hover\:text-\[\#3b71ca\]:hover {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.hover\:text-\[\#8b8b8b\]:hover {
  --tw-text-opacity: 1;
  color: rgb(139 139 139 / var(--tw-text-opacity));
}

.hover\:text-cyan-400:hover {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.hover\:text-yellow-300:hover {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
}

.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:\!opacity-90:hover {
  opacity: .9 !important;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:\!shadow-none:hover {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:outline-none:hover {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.hover\:before\:opacity-\[0\.04\]:hover:before {
  content: var(--tw-content);
  opacity: .04;
}

.hover\:before\:shadow-\[0px_0px_0px_13px_rgba\(0\,0\,0\,0\.6\)\]:hover:before {
  content: var(--tw-content);
  --tw-shadow: 0px 0px 0px 13px #0009;
  --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:rounded-\[50\%\]:focus {
  border-radius: 50%;
}

.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 113 202 / var(--tw-border-opacity));
}

.focus\:border-slate-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.focus\:bg-\[\#00000014\]:focus {
  background-color: #00000014;
}

.focus\:bg-\[\#00000026\]:focus {
  background-color: #00000026;
}

.focus\:bg-\[\#eee\]:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}

.focus\:bg-neutral-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.focus\:text-\[\#3b71ca\]:focus {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.focus\:text-primary:focus {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.focus\:\!opacity-90:focus {
  opacity: .9 !important;
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:shadow-te-primary:focus {
  --tw-shadow: 0 0 0 1px #3b71ca;
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-purple-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}

.focus\:ring-slate-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity));
}

.focus\:transition-\[border-color_0\.2s\]:focus {
  transition-property: border-color .2s;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.focus\:placeholder\:opacity-100:focus::placeholder {
  opacity: 1;
}

.focus\:before\:scale-100:focus:before {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.focus\:before\:opacity-\[0\.12\]:focus:before {
  content: var(--tw-content);
  opacity: .12;
}

.focus\:before\:shadow-\[0px_0px_0px_13px_rgba\(0\,0\,0\,0\.6\)\]:focus:before {
  content: var(--tw-content);
  --tw-shadow: 0px 0px 0px 13px #0009;
  --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:before\:transition-\[box-shadow_0\.2s\,transform_0\.2s\]:focus:before {
  content: var(--tw-content);
  transition-property: box-shadow .2s, transform .2s;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.focus\:after\:absolute:focus:after {
  content: var(--tw-content);
  position: absolute;
}

.focus\:after\:z-\[1\]:focus:after {
  content: var(--tw-content);
  z-index: 1;
}

.focus\:after\:block:focus:after {
  content: var(--tw-content);
  display: block;
}

.focus\:after\:h-\[0\.875rem\]:focus:after {
  content: var(--tw-content);
  height: .875rem;
}

.focus\:after\:w-\[0\.875rem\]:focus:after {
  content: var(--tw-content);
  width: .875rem;
}

.focus\:after\:rounded-\[0\.125rem\]:focus:after {
  content: var(--tw-content);
  border-radius: .125rem;
}

.focus\:after\:content-\[\'\'\]:focus:after {
  --tw-content: "";
  content: var(--tw-content);
}

.checked\:focus\:before\:scale-100:focus:checked:before {
  content: var(--tw-content);
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.checked\:focus\:before\:shadow-\[0px_0px_0px_13px_\#3b71ca\]:focus:checked:before {
  content: var(--tw-content);
  --tw-shadow: 0px 0px 0px 13px #3b71ca;
  --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.checked\:focus\:before\:transition-\[box-shadow_0\.2s\,transform_0\.2s\]:focus:checked:before {
  content: var(--tw-content);
  transition-property: box-shadow .2s, transform .2s;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.checked\:focus\:after\:-mt-px:focus:checked:after {
  content: var(--tw-content);
  margin-top: -1px;
}

.checked\:focus\:after\:ml-\[0\.25rem\]:focus:checked:after {
  content: var(--tw-content);
  margin-left: .25rem;
}

.checked\:focus\:after\:h-\[0\.8125rem\]:focus:checked:after {
  content: var(--tw-content);
  height: .8125rem;
}

.checked\:focus\:after\:w-\[0\.375rem\]:focus:checked:after {
  content: var(--tw-content);
  width: .375rem;
}

.checked\:focus\:after\:rotate-45:focus:checked:after {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.checked\:focus\:after\:rounded-none:focus:checked:after {
  content: var(--tw-content);
  border-radius: 0;
}

.checked\:focus\:after\:border-\[0\.125rem\]:focus:checked:after {
  content: var(--tw-content);
  border-width: .125rem;
}

.checked\:focus\:after\:border-l-0:focus:checked:after {
  content: var(--tw-content);
  border-left-width: 0;
}

.checked\:focus\:after\:border-t-0:focus:checked:after {
  content: var(--tw-content);
  border-top-width: 0;
}

.checked\:focus\:after\:border-solid:focus:checked:after {
  content: var(--tw-content);
  border-style: solid;
}

.checked\:focus\:after\:border-white:focus:checked:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.checked\:focus\:after\:bg-transparent:focus:checked:after {
  content: var(--tw-content);
  background-color: #0000;
}

.active\:bg-\[\#cacfd1\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(202 207 209 / var(--tw-bg-opacity));
}

.group\/x:hover .group-hover\/x\:h-\[11px\] {
  height: 11px;
}

.group\/y:hover .group-hover\/y\:w-\[11px\] {
  width: 11px;
}

.group\/x:hover .group-hover\/x\:bg-\[\#999\], .group\/y:hover .group-hover\/y\:bg-\[\#999\] {
  --tw-bg-opacity: 1;
  background-color: rgb(153 153 153 / var(--tw-bg-opacity));
}

.group\/ps:hover .group-hover\/ps\:opacity-100 {
  opacity: 1;
}

.group\/ps:hover .group-hover\/ps\:opacity-60 {
  opacity: .6;
}

.group\/x:focus .group-focus\/x\:h-\[11px\] {
  height: 11px;
}

.group\/y:focus .group-focus\/y\:w-\[11px\] {
  width: 11px;
}

.group\/x:focus .group-focus\/x\:bg-\[\#999\], .group\/y:focus .group-focus\/y\:bg-\[\#999\] {
  --tw-bg-opacity: 1;
  background-color: rgb(153 153 153 / var(--tw-bg-opacity));
}

.group\/ps:focus .group-focus\/ps\:opacity-100 {
  opacity: 1;
}

.group\/ps:focus .group-focus\/ps\:opacity-60 {
  opacity: .6;
}

.group\/ps:active .group-active\/ps\:opacity-100 {
  opacity: 1;
}

.group\/ps.ps--active-x .group-\[\&\.ps--active-x\]\/ps\:block, .group\/ps.ps--active-y .group-\[\&\.ps--active-y\]\/ps\:block {
  display: block;
}

.group\/x.ps--clicking .group-\[\&\.ps--clicking\]\/x\:h-\[11px\] {
  height: 11px;
}

.group\/y.ps--clicking .group-\[\&\.ps--clicking\]\/y\:w-\[11px\] {
  width: 11px;
}

.group[data-te-datepicker-cell-current] .group-\[\[data-te-datepicker-cell-current\]\]\:border {
  border-width: 1px;
}

.group[data-te-datepicker-cell-current] .group-\[\[data-te-datepicker-cell-current\]\]\:border-solid {
  border-style: solid;
}

.group[data-te-datepicker-cell-current] .group-\[\[data-te-datepicker-cell-current\]\]\:border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.group\/ps.ps--active-x .group-\[\&\.ps--active-x\]\/ps\:bg-transparent, .group\/ps.ps--active-y .group-\[\&\.ps--active-y\]\/ps\:bg-transparent {
  background-color: #0000;
}

.group\/x.ps--clicking .group-\[\&\.ps--clicking\]\/x\:bg-\[\#999\], .group\/y.ps--clicking .group-\[\&\.ps--clicking\]\/y\:bg-\[\#999\] {
  --tw-bg-opacity: 1;
  background-color: rgb(153 153 153 / var(--tw-bg-opacity));
}

.group:not([data-te-datepicker-cell-disabled]):not([data-te-datepicker-cell-selected]):hover .group-\[\:not\(\[data-te-datepicker-cell-disabled\]\)\:not\(\[data-te-datepicker-cell-selected\]\)\:hover\]\:bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

.group:not([data-te-datepicker-cell-selected])[data-te-datepicker-cell-focused] .group-\[\:not\(\[data-te-datepicker-cell-selected\]\)\[data-te-datepicker-cell-focused\]\]\:bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.group[data-te-datepicker-cell-selected] .group-\[\[data-te-datepicker-cell-selected\]\]\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(59 113 202 / var(--tw-bg-opacity));
}

.group[data-te-datepicker-cell-selected] .group-\[\[data-te-datepicker-cell-selected\]\]\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group\/ps.ps--scrolling-x .group-\[\&\.ps--scrolling-x\]\/ps\:opacity-60, .group\/ps.ps--scrolling-y .group-\[\&\.ps--scrolling-y\]\/ps\:opacity-60 {
  opacity: .6;
}

.peer:focus ~ .peer-focus\:-translate-y-\[0\.75rem\] {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:-translate-y-\[0\.9rem\] {
  --tw-translate-y: -.9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:-translate-y-\[1\.15rem\] {
  --tw-translate-y: -1.15rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:scale-\[0\.8\] {
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.peer:focus ~ .peer-focus\:text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-primary {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

.data-\[te-datepicker-cell-disabled\]\:pointer-events-none[data-te-datepicker-cell-disabled] {
  pointer-events: none;
}

.data-\[te-carousel-fade\]\:z-0[data-te-carousel-fade] {
  z-index: 0;
}

.data-\[te-carousel-fade\]\:z-\[1\][data-te-carousel-fade] {
  z-index: 1;
}

.data-\[te-input-state-active\]\:block[data-te-input-state-active] {
  display: block;
}

.data-\[popper-reference-hidden\]\:hidden[data-popper-reference-hidden] {
  display: none;
}

.data-\[te-input-state-active\]\:-translate-y-\[0\.75rem\][data-te-input-state-active] {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-input-state-active\]\:-translate-y-\[0\.9rem\][data-te-input-state-active] {
  --tw-translate-y: -.9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-input-state-active\]\:-translate-y-\[1\.15rem\][data-te-input-state-active] {
  --tw-translate-y: -1.15rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-input-state-active\]\:scale-\[0\.8\][data-te-input-state-active] {
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-select-open\]\:scale-100[data-te-select-open] {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[te-datepicker-cell-disabled\]\:cursor-default[data-te-datepicker-cell-disabled], .data-\[te-input-disabled\]\:cursor-default[data-te-input-disabled], .data-\[te-select-option-disabled\]\:cursor-default[data-te-select-option-disabled], .data-\[te-select-selected\]\:data-\[te-select-option-disabled\]\:cursor-default[data-te-select-option-disabled][data-te-select-selected] {
  cursor: default;
}

.data-\[te-input-disabled\]\:bg-\[\#e9ecef\][data-te-input-disabled] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 236 239 / var(--tw-bg-opacity));
}

.data-\[te-input-multiple-active\]\:bg-black\/5[data-te-input-multiple-active], .data-\[te-input-state-active\]\:bg-black\/5[data-te-input-state-active] {
  background-color: #0000000d;
}

.data-\[te-select-option-selected\]\:bg-black\/\[0\.02\][data-te-select-option-selected] {
  background-color: #00000005;
}

.data-\[te-select-option-selected\]\:data-\[te-input-state-active\]\:bg-black\/5[data-te-input-state-active][data-te-select-option-selected] {
  background-color: #0000000d;
}

.data-\[te-select-selected\]\:data-\[te-select-option-disabled\]\:bg-transparent[data-te-select-option-disabled][data-te-select-selected] {
  background-color: #0000;
}

.data-\[te-datepicker-cell-disabled\]\:text-neutral-300[data-te-datepicker-cell-disabled] {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.data-\[te-select-option-disabled\]\:text-gray-400[data-te-select-option-disabled], .data-\[te-select-selected\]\:data-\[te-select-option-disabled\]\:text-gray-400[data-te-select-option-disabled][data-te-select-selected] {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.data-\[te-carousel-fade\]\:opacity-0[data-te-carousel-fade] {
  opacity: 0;
}

.data-\[te-carousel-fade\]\:opacity-100[data-te-carousel-fade], .data-\[te-select-open\]\:opacity-100[data-te-select-open] {
  opacity: 1;
}

.data-\[te-carousel-fade\]\:duration-0[data-te-carousel-fade] {
  transition-duration: 0s;
}

.data-\[te-input-state-active\]\:placeholder\:opacity-100[data-te-input-state-active]::placeholder {
  opacity: 1;
}

.data-\[te-datepicker-cell-disabled\]\:hover\:cursor-default:hover[data-te-datepicker-cell-disabled] {
  cursor: default;
}

.group[data-te-datepicker-cell-focused] .group-\[\[data-te-datepicker-cell-focused\]\]\:data-\[te-datepicker-cell-selected\]\:bg-primary[data-te-datepicker-cell-selected] {
  --tw-bg-opacity: 1;
  background-color: rgb(59 113 202 / var(--tw-bg-opacity));
}

.group\/validation[data-te-was-validated] .group-data-\[te-was-validated\]\/validation\:mb-4 {
  margin-bottom: 1rem;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-x-0, .group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-x-0 {
  border-left-width: 0;
  border-right-width: 0;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-l-0 {
  border-left-width: 0;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-r-0 {
  border-right-width: 0;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-t {
  border-top-width: 1px;
}

.group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-l-0 {
  border-left-width: 0;
}

.group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-r-0 {
  border-right-width: 0;
}

.group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-t {
  border-top-width: 1px;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-solid, .group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-solid {
  border-style: solid;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(59 113 202 / var(--tw-border-opacity));
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:border-t-transparent, .group[data-te-input-state-active] .group-data-\[te-input-state-active\]\:border-t-transparent {
  border-top-color: #0000;
}

.group\/opt[data-te-select-option-group-ref] .group-data-\[te-select-option-group-ref\]\/opt\:pl-7 {
  padding-left: 1.75rem;
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[-1px_0_0_\#3b71ca\,_0_1px_0_0_\#3b71ca\,_0_-1px_0_0_\#3b71ca\] {
  --tw-shadow: -1px 0 0 #3b71ca, 0 1px 0 0 #3b71ca, 0 -1px 0 0 #3b71ca;
  --tw-shadow-colored: -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[-1px_0_0_\#ffffff\,_0_1px_0_0_\#ffffff\,_0_-1px_0_0_\#ffffff\] {
  --tw-shadow: -1px 0 0 #fff, 0 1px 0 0 #fff, 0 -1px 0 0 #fff;
  --tw-shadow-colored: -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[0_1px_0_0_\#3b71ca\] {
  --tw-shadow: 0 1px 0 0 #3b71ca;
  --tw-shadow-colored: 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[0_1px_0_0_\#ffffff\] {
  --tw-shadow: 0 1px 0 0 #fff;
  --tw-shadow-colored: 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[1px_0_0_\#3b71ca\,_0_-1px_0_0_\#3b71ca\,_0_1px_0_0_\#3b71ca\] {
  --tw-shadow: 1px 0 0 #3b71ca, 0 -1px 0 0 #3b71ca, 0 1px 0 0 #3b71ca;
  --tw-shadow-colored: 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group[data-te-input-focused] .group-data-\[te-input-focused\]\:shadow-\[1px_0_0_\#ffffff\,_0_-1px_0_0_\#ffffff\,_0_1px_0_0_\#ffffff\] {
  --tw-shadow: 1px 0 0 #fff, 0 -1px 0 0 #fff, 0 1px 0 0 #fff;
  --tw-shadow-colored: 1px 0 0 var(--tw-shadow-color), 0 -1px 0 0 var(--tw-shadow-color), 0 1px 0 0 var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group\/validation[data-te-was-validated] .peer:valid ~ .group-data-\[te-was-validated\]\/validation\:peer-valid\:block {
  display: block;
}

.group\/validation[data-te-was-validated] .peer:valid ~ .group-data-\[te-was-validated\]\/validation\:peer-valid\:text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.group\/validation[data-te-was-validated] .peer:invalid ~ .group-data-\[te-was-validated\]\/validation\:peer-invalid\:block {
  display: block;
}

.group\/validation[data-te-was-validated] .peer:invalid ~ .group-data-\[te-was-validated\]\/validation\:peer-invalid\:text-\[rgb\(220\,76\,100\)\] {
  --tw-text-opacity: 1;
  color: rgb(220 76 100 / var(--tw-text-opacity));
}

.peer[data-te-input-state-active] ~ .peer-data-\[te-input-state-active\]\:-translate-y-\[0\.75rem\] {
  --tw-translate-y: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer[data-te-input-state-active] ~ .peer-data-\[te-input-state-active\]\:-translate-y-\[0\.9rem\] {
  --tw-translate-y: -.9rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer[data-te-input-state-active] ~ .peer-data-\[te-input-state-active\]\:-translate-y-\[1\.15rem\] {
  --tw-translate-y: -1.15rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer[data-te-input-state-active] ~ .peer-data-\[te-input-state-active\]\:scale-\[0\.8\] {
  --tw-scale-x: .8;
  --tw-scale-y: .8;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer[data-te-input-focused] ~ .peer-data-\[te-input-focused\]\:\!text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.peer[data-te-input-focused] ~ .peer-data-\[te-input-focused\]\:text-primary {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

:is([dir="rtl"] .rtl\:\!left-auto) {
  left: auto !important;
}

:is([dir="rtl"] .rtl\:\!origin-\[50\%_50\%_0\]) {
  transform-origin: 50% 50% 0 !important;
}

:is([dir="rtl"] .rtl\:\[direction\:rtl\]) {
  direction: rtl;
}

@media (prefers-reduced-motion: reduce) {
  .motion-reduce\:animate-none {
    animation: none;
  }

  .motion-reduce\:transition-none {
    transition-property: none;
  }
}

:is(.dark .dark\:border-0) {
  border-width: 0;
}

:is(.dark .dark\:border-gray-600) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-neutral-500) {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-neutral-600) {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity));
}

:is(.dark .dark\:border-primary-400) {
  --tw-border-opacity: 1;
  border-color: rgb(143 174 224 / var(--tw-border-opacity));
}

:is(.dark .dark\:bg-gray-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-neutral-600) {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-neutral-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-transparent) {
  background-color: #0000;
}

:is(.dark .dark\:bg-zinc-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-zinc-600\/50) {
  background-color: #52525b80;
}

:is(.dark .dark\:bg-zinc-700) {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

:is(.dark .dark\:bg-zinc-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

:is(.dark .dark\:fill-gray-400) {
  fill: #9ca3af;
}

:is(.dark .dark\:\!text-primary-400) {
  --tw-text-opacity: 1 !important;
  color: rgb(143 174 224 / var(--tw-text-opacity)) !important;
}

:is(.dark .dark\:text-gray-200) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-300) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-gray-400) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-neutral-200) {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:is(.dark .dark\:placeholder-gray-400)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}

:is(.dark .dark\:placeholder\:text-gray-200)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

:is(.dark .dark\:checked\:border-primary:checked) {
  --tw-border-opacity: 1;
  border-color: rgb(59 113 202 / var(--tw-border-opacity));
}

:is(.dark .dark\:checked\:bg-primary:checked) {
  --tw-bg-opacity: 1;
  background-color: rgb(59 113 202 / var(--tw-bg-opacity));
}

:is(.dark .dark\:hover\:bg-white\/10:hover) {
  background-color: #ffffff1a;
}

:is(.dark .dark\:hover\:fill-gray-100:hover) {
  fill: #f3f4f6;
}

:is(.dark .dark\:hover\:text-\[\#3b71ca\]:hover) {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

:is(.dark .dark\:hover\:text-primary-400:hover) {
  --tw-text-opacity: 1;
  color: rgb(143 174 224 / var(--tw-text-opacity));
}

:is(.dark .dark\:focus\:bg-white\/10:focus) {
  background-color: #ffffff1a;
}

:is(.dark .dark\:focus\:text-\[\#3b71ca\]:focus) {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

:is(.dark .dark\:focus\:text-primary-400:focus) {
  --tw-text-opacity: 1;
  color: rgb(143 174 224 / var(--tw-text-opacity));
}

:is(.dark .dark\:focus\:before\:shadow-\[0px_0px_0px_13px_rgba\(255\,255\,255\,0\.4\)\]:focus):before {
  content: var(--tw-content);
  --tw-shadow: 0px 0px 0px 13px #fff6;
  --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is(.dark .dark\:checked\:focus\:before\:shadow-\[0px_0px_0px_13px_\#3b71ca\]:focus:checked):before {
  content: var(--tw-content);
  --tw-shadow: 0px 0px 0px 13px #3b71ca;
  --tw-shadow-colored: 0px 0px 0px 13px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is(.dark .group[data-te-datepicker-cell-current] .dark\:group-\[\[data-te-datepicker-cell-current\]\]\:border-white) {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

:is(.dark .group:not([data-te-datepicker-cell-disabled]):not([data-te-datepicker-cell-selected]):hover .dark\:group-\[\:not\(\[data-te-datepicker-cell-disabled\]\)\:not\(\[data-te-datepicker-cell-selected\]\)\:hover\]\:bg-white\/10), :is(.dark .group:not([data-te-datepicker-cell-selected])[data-te-datepicker-cell-focused] .dark\:group-\[\:not\(\[data-te-datepicker-cell-selected\]\)\[data-te-datepicker-cell-focused\]\]\:bg-white\/10) {
  background-color: #ffffff1a;
}

:is(.dark .group[data-te-datepicker-cell-disabled] .dark\:group-\[\[data-te-datepicker-cell-disabled\]\]\:text-neutral-500) {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

:is(.dark .peer:focus ~ .dark\:peer-focus\:text-gray-200) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

:is(.dark .peer:focus ~ .dark\:peer-focus\:text-primary) {
  --tw-text-opacity: 1;
  color: rgb(59 113 202 / var(--tw-text-opacity));
}

:is(.dark .dark\:data-\[te-input-disabled\]\:bg-zinc-600[data-te-input-disabled]) {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

:is(.dark .dark\:data-\[te-input-multiple-active\]\:bg-white\/30[data-te-input-multiple-active]), :is(.dark .dark\:data-\[te-input-state-active\]\:bg-white\/30[data-te-input-state-active]), :is(.dark .dark\:data-\[te-select-option-selected\]\:data-\[te-input-state-active\]\:bg-white\/30[data-te-input-state-active][data-te-select-option-selected]) {
  background-color: #ffffff4d;
}

:is(.dark .dark\:data-\[te-select-option-disabled\]\:text-gray-400[data-te-select-option-disabled]) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:max-w-\[800\] {
    max-width: 800px;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 320px) {
  @media not all and (min-width: 768px) {
    @media (orientation: landscape) {
      .xs\:max-md\:landscape\:mt-24 {
        margin-top: 6rem;
      }

      .xs\:max-md\:landscape\:h-8 {
        height: 2rem;
      }

      .xs\:max-md\:landscape\:h-\[360px\] {
        height: 360px;
      }

      .xs\:max-md\:landscape\:h-full {
        height: 100%;
      }

      .xs\:max-md\:landscape\:w-8 {
        width: 2rem;
      }

      .xs\:max-md\:landscape\:w-\[475px\] {
        width: 475px;
      }

      .xs\:max-md\:landscape\:flex-row {
        flex-direction: row;
      }
    }
  }

  @media (max-width: 825px) {
    @media (orientation: landscape) {
      .min-\[320px\]\:max-\[825px\]\:landscape\:h-auto {
        height: auto;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:min-h-\[305px\] {
        min-height: 305px;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:min-h-\[auto\] {
        min-height: auto;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:min-w-\[auto\] {
        min-width: auto;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:\!flex-row {
        flex-direction: row !important;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:flex-col {
        flex-direction: column;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:\!justify-around {
        justify-content: space-around !important;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:overflow-y-auto {
        overflow-y: auto;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:rounded-lg {
        border-radius: .5rem;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:rounded-bl-lg {
        border-bottom-left-radius: .5rem;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:rounded-bl-none {
        border-bottom-left-radius: 0;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:rounded-tr-none {
        border-top-right-radius: 0;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:p-\[10px\] {
        padding: 10px;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:pr-\[10px\] {
        padding-right: 10px;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:text-5xl {
        font-size: 3rem;
        line-height: 1;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:text-\[3rem\] {
        font-size: 3rem;
      }

      .min-\[320px\]\:max-\[825px\]\:landscape\:font-normal {
        font-weight: 400;
      }
    }
  }
}

.\[\&\.ps--clicking\]\:bg-\[\#eee\].ps--clicking {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}

.\[\&\.ps--clicking\]\:\!opacity-90.ps--clicking {
  opacity: .9 !important;
}

.\[\&\:\:-webkit-scrollbar-button\]\:block::-webkit-scrollbar-button {
  display: block;
}

.\[\&\:\:-webkit-scrollbar-button\]\:h-0::-webkit-scrollbar-button {
  height: 0;
}

.\[\&\:\:-webkit-scrollbar-button\]\:bg-transparent::-webkit-scrollbar-button {
  background-color: #0000;
}

.\[\&\:\:-webkit-scrollbar-thumb\]\:h-\[50px\]::-webkit-scrollbar-thumb {
  height: 50px;
}

.\[\&\:\:-webkit-scrollbar-thumb\]\:rounded::-webkit-scrollbar-thumb {
  border-radius: .25rem;
}

.\[\&\:\:-webkit-scrollbar-thumb\]\:bg-\[\#999\]::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(153 153 153 / var(--tw-bg-opacity));
}

.\[\&\:\:-webkit-scrollbar-track-piece\]\:rounded-none::-webkit-scrollbar-track-piece {
  border-radius: 0;
}

.\[\&\:\:-webkit-scrollbar-track-piece\]\:rounded-l::-webkit-scrollbar-track-piece {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.\[\&\:\:-webkit-scrollbar-track-piece\]\:bg-transparent::-webkit-scrollbar-track-piece {
  background-color: #0000;
}

.\[\&\:\:-webkit-scrollbar\]\:h-1::-webkit-scrollbar {
  height: .25rem;
}

.\[\&\:\:-webkit-scrollbar\]\:w-1::-webkit-scrollbar {
  width: .25rem;
}

.\[\&\:not\(\[data-te-input-placeholder-active\]\)\]\:placeholder\:opacity-0:not([data-te-input-placeholder-active])::placeholder {
  opacity: 0;
}

.hover\:\[\&\:not\(\[data-te-select-option-disabled\]\)\]\:bg-black\/5:not([data-te-select-option-disabled]):hover {
  background-color: #0000000d;
}

:is(.dark .dark\:hover\:\[\&\:not\(\[data-te-select-option-disabled\]\)\]\:bg-white\/30:not([data-te-select-option-disabled]):hover) {
  background-color: #ffffff4d;
}

.\[\&\>svg\]\:mx-auto > svg {
  margin-left: auto;
  margin-right: auto;
}

.\[\&\>svg\]\:h-4 > svg {
  height: 1rem;
}

.\[\&\>svg\]\:h-5 > svg {
  height: 1.25rem;
}

.\[\&\>svg\]\:w-4 > svg {
  width: 1rem;
}

.\[\&\>svg\]\:w-5 > svg {
  width: 1.25rem;
}

.\[\&\>svg\]\:rotate-180 > svg {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>svg\]\:fill-neutral-500 > svg {
  fill: #737373;
}

:is(.dark .dark\:\[\&\>svg\]\:fill-white > svg) {
  fill: #fff;
}

/*# sourceMappingURL=index.477e92a5.css.map */
